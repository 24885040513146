/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');

//Variables
:root {
	--font-primary: 'Rubik', sans-serif;
	--blue-1: #4b27ef;
	--blue-2: #4a27ed;
	--blue-3: #6848f3;

	--pink-1: #ea345f;
	--pink-2: #ff3160;
	--cbody: #f6f6fc;
	--white: #ffffff;
	--black: #000000;
	--black-2: #17191e;
	--gray-1: #757575;
	--text-font-1: #4c4444;
}
//Estandarización
html {
	box-sizing: border-box;
	font-size: 62.5%;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

html,
body {
	height: 100%;
	margin: 0px;
}

body {
	font-size: 1.6rem;
	font-family: var(--font-primary) !important;
	overflow-y: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
figure {
	margin: 0;
	padding: 0;
}

input,
button {
	outline: none;
	border: none;
	background: none;
	margin: 0;
	padding: 0;
}

//Modificaciones Generales

.text-truncate {
	width: 100%;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
}

.custom-tooltip {
	background-color: #1b0845;
	color: white;
	padding: 5px 15px;
	border-radius: 8px;

	visibility: visible;
	position: absolute !important;
	bottom: calc(100% + 12px);
	left: 50%;
	transform: translateX(-50%);
	box-sizing: border-box;
	// border-radius: 4px;
	text-align: center;
	font-family: sans-serif;

	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		font-size: 10px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 10px 5px 0 5px;
		border-color: #1b0845 transparent transparent transparent;
	}
}

.v-backdrop {
	background: rgba(0, 0, 0, 0.42);
	backdrop-filter: blur(2px);
}
.v-dialog {
	max-width: none !important;
	.mat-dialog-container {
		padding: 0;
		border-radius: 0;
		max-width: none;
		max-height: none;
		background: rgba(0, 0, 0, 0.06);
	}
}

// Set Input autoComplete

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0px 1000px #f6f6fc inset;
	font-family: 'Rubik';
	font-style: normal;
}

// Set Menu in table
.menuAttentionTable {
	& ~ .cdk-overlay-connected-position-bounding-box {
		.mat-menu-panel {
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
			top: -24px;
			right: 28px;
			position: absolute;
			overflow: visible;
			min-height: 0;

			.mat-menu-content {
				padding: 0;
				background: #ffffff;
				border-radius: 2px;
				display: flex;
				flex-direction: column;
				.mat-menu-item {
					padding: 0;
					height: auto;
				}
			}
		}
	}
}
// Set Menu Header
.menuMainHeader {
	& ~ .cdk-overlay-connected-position-bounding-box {
		.mat-menu-panel {
			// box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
			box-shadow: none;
			filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
			top: 12px;
			right: 25px;
			// right: 15px;
			position: absolute;
			overflow: visible;

			&::after {
				content: '';
				position: absolute;
				top: -8.5px;
				right: 14px;
				width: 12px;
				height: 10px;
				background: #ffffff;
				clip-path: polygon(50% 0%, 100% 100%, 00% 100%);
			}

			.mat-menu-content {
				padding: 0;
				background: #ffffff;
				border-radius: 2px;
				display: flex;
				flex-direction: column;
				width: 157px;
				.mat-menu-item {
					padding: 0;
					height: auto;
				}
			}
		}
	}
}

@media screen and (min-width: 600px) {
	.menuMainHeader {
		& ~ .cdk-overlay-connected-position-bounding-box {
			.mat-menu-panel {
				right: 26px;
			}
		}
	}
}

// Select Custom
.cdk-overlay-container {
	.cdk-overlay-connected-position-bounding-box {
		.cdk-overlay-pane {
			transform: none !important;
		}
	}
}
.panelOfSelectCustom {
	box-sizing: border-box;
	position: absolute;
	top: 38px !important;
	left: -16px !important;
	min-width: 100% !important;
	width: calc(100% + 33px) !important;
	max-width: calc(100% + 33px) !important;
	height: fit-content !important;
	max-height: calc(3 * 44px) !important;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) !important;
	background: #ffffff;
	border-radius: 2px;
	border: 1px solid #757575;
	.optionCustom {
		height: 43px !important;
		padding: 11px 15px !important;

		&.mat-active {
			background: #ffffff !important;
			&:hover {
				background: #eff1fd !important;
			}
			.mat-option-text {
				background: transparent;
			}
		}

		&.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
			background: #ffffff;
		}
		&.mat-selected:not(.mat-option-multiple) {
			background: #ffffff;
		}

		&.mat-selected:not(.mat-option-multiple) {
			background: #ffffff;
		}

		&:hover:not(.mat-option-disabled),
		&:focus:not(.mat-option-disabled) {
			background: #eff1fd;
		}
	}
}

.tdCenter {
	.mat-sort-header-container {
		display: flex;
		justify-content: center;
	}
}
.vl-checkbox-form {
	display: grid;
	grid-template-columns: 20px 1fr;
	grid-template-rows: auto;
	column-gap: 6px;
	text-align: left;
	cursor: pointer;
	&__wrapper {
		position: relative;
		display: grid;
		place-items: center;
		width: 16px;
		height: 16px;
		border-radius: 4px;
		cursor: pointer;

		input {
			display: none;

			&:checked ~ .vl-checkbox-form__wrapper__check {
				background-color: var(--pink-2);

				&::after {
					border-color: white;
				}
			}
		}
		.vl-checkbox-form__wrapper__check {
			border-radius: 4px;
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			display: grid;
			justify-items: center;
			box-shadow: var(--elevation-3);
			background: var(--white);
			&:after {
				content: '';
				position: absolute;
				display: block;
				width: 5px;
				height: 9px;
				margin-top: 3px;
				border: solid transparent;
				border-width: 0 2px 2px 0;
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
			}
		}
	}

	&__text {
		font-family: var(--font-1);
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		color: #000000;
	}
}

.vlbtn {
	padding: 7px 14px;
	display: grid;
	place-items: center;
	border-radius: 20px;
	border: 1px solid var(--pink-1);
	background-color: var(--pink-1);
	color: var(--white);
	font-family: var(--font-1);
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	text-decoration: none;
	text-transform: capitalize;
	&:not(:disabled) {
		cursor: pointer;
	}

	&.alt {
		background-color: var(--white);
		color: var(--pink-1);
	}

	&:disabled {
		border: 1px solid var(--blue-2);
		background-color: var(--blue-2);
		color: var(--white);
		&.alt {
			border: 1px solid var(--blue-2);
			background-color: var(--white);
			color: var(--blue-2);
		}
	}
}

.vl-code {
	&-sim {
		color: #cbd5e1;
	}
	&-tag {
		color: var(--blue-6, #6094e3);
	}
	&-att {
		color: var(--white);
	}
	&-val {
		color: var(--pink-2);
	}
	&-spc {
		display: inline-block;
		width: 19px;
		height: 19px;
	}
}
